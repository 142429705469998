<template>
  <div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Logout',
  data() {
    return {}
  },
  async mounted() {
    await this.logout()
  },
  methods: {
    async logout() {
      this.$store.dispatch('auth/logout')
      this.$router.push('/login')
      setTimeout(function () {
        window.location.reload()
      }, 500)
    },
  },
}
</script>
